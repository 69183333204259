/* @media (prefers-color-scheme: dark) { */
  .loginActions .loadingText,
  .loginActions .loadingSpinner {
    color: var(--fusend-color-text-primary);
  }
  .loginButton {
    color: var(--fusend-color-text-primary);
    --background: var(--fusend-color-light);
    --background-activated: var(--fusend-color-light);
    --background-focused: var(--fusend-color-light);
    --background-hover: var(--fusend-color-light);
  }

  .tos {
    color: var(--fusend-color-text-primary);
  }
/* } */
