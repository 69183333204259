.qrCodeContent {
  position: relative;

  h4,
  p {
    text-align: center;
  }
}

.qrCodeWrapper {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  padding: 40px;
  width: 300px;
  height: 300px;
  background-color: var(--fusend-color-black);
  border-radius: 15px;
  margin-top: 39px;
}

.qrCodeName {
  margin-top: 50px;
}

.qrCodeUsername {
  margin-bottom: 0;
}

.qrCodeProfilePic {
  position: absolute;
  width: 50px;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.qrCodeLogoWrapper {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .qrCodeLogo {
    width: 185px;
  }
}

.qrCodeLogoText {
  margin-top: 10px;
  font-size: 14px;
  color: var(--fusend-color-black);
}