.profileHeaderContent {
  --padding-top: 25px;
  --padding-start: 25px;
  --padding-end: 25px;
  --background: var(--fusend-color-sideMenu-background);

  .profilePicture {
    width: 100px;
    height: 100px;
    font-size: 30px;
    margin-left: 50%;
    transform: translateX(-50%);
  }

  .profileHeaderName {
    margin-top: 34px;
    font-size: 20px;
    text-align: center;
  }

  .profileHeaderUserName {
    margin-top: 8px;
    margin-bottom: 30px;
    color: #9AA5B1;
  }
}