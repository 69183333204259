.value-container,
.editable-value-container {
    display: flex;
    flex-direction: column;
    padding: 7px 0;
    max-width: 480px;
    width: 100%;
}

.editable-value-action-row {
    align-items: center;
}

.value-title,
.editable-value-title,
.editable-value-value {
    font-style: normal;
    letter-spacing: 0em;
    text-align: left;
    margin: 0px;
}

.value-title,
.editable-value-title {
    color: var(--trip-color-primary);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 2px;
}

.value-value {
    overflow: hidden;
}

.editable-value-value {
    background-color: var(--trip-color-dark);
    color: var(--trip-color-light);
    border-radius: 8px;
    --padding-top: 12px;
    --padding-bottom: 12px;
    --padding-start: 18px;
    --padding-end: 18px;
    font-size: 18px;
    font-weight: 300;
    line-height: 27px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.value-copy {
    flex-grow: unset;
    font-size: 24px;
    margin-left: 8px;
}

.editable-value-error,
.important-note {
    color: var(--fusend-color-red);
    font-size: 11px;
    font-weight: 400;
    line-height: 16.5px;
    margin-top: 4px;
}

.editable-value-error {
    font-weight: 600;
}

.submit-button-container .submit-button {
    height: 40px;
    width: 264px;
    background-color: var(--trip-color-submit);
    color: var(--trip-color-dark);
    border-radius: 4px;
}

.submit-button-container .submit-button:active {
    background-color: var(--trip-color-submit-pressed);
}

.submit-button-container .submit-button:disabled {
    background-color: var(--trip-color-submit-disabled);
}

.multi-select-options {
    display: flex;
    flex-direction: column;

    .selectOption {
        background-color: var(--trip-color-dark);
        border-radius: 8px;
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        padding: 12px 18px;
    }

    .value {
        color: var(--trip-color-light);
        font-size: 18px;
        font-weight: 300;
        line-height: 27px;
        margin-left: 4px;
    }

    .indicator {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.editable-value-value.editable-textarea {
    font-size: 12px;
    line-height: 16px;
    padding: 8px !important;
    display: block;
    white-space: normal;
}