.messageRow {
  display: flex;
  flex-direction: row;

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }
}

.messageTextContainer {
  display: flex;
  flex-direction: column;
  margin: 0 12px;

  &.left {
    align-items: flex-start;
  }

  &.right {
    align-items: flex-end;
  }
}

.messageRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.messageText {
  background-color: var(--fusend-color-background-light);
  color: var(--fusend-color-dark);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.messageText,
.messageReply {
  display: inline-block;
  padding: 10px 16px;
  border-radius: 12px;
  vertical-align: middle;
  word-wrap: break-word;
  overflow-wrap: anywhere;
  min-height: 38px;
  white-space: pre-line;
}

.messageContainer {
  z-index: -1;
  margin-top: 10px;
  -webkit-user-select: text;
  user-select: text;

  ion-row,
  ion-col {
    padding: 0;
  }
}

.myMessage {
  text-align: right;

  .messageText {
    background-color: var(--fusend-color-primary);
    color: var(--fusend-color-white);
    font-size: 16px;
    text-align: left;
    font-weight: 400;
    user-select: text;
    -webkit-user-select: text;
  }
}

.messageProfilePicMissingSender,
.messageProfilePic {
  width: 32px;
  height: 32px;

  .fallbackProfilePicTextContainer {
    font-size: 1em;
  }

  &.hide {
    visibility: hidden;
  }
}

.messageProfilePicMissingSender {
  border-radius: 50%;
  background-color: var(--fusend-color-darker);
  display: flex;
  align-items: center;
  justify-content: center;
}

.systemMessage {
  text-align: center;
  font-size: 12px;
  color: var(--fusend-color-darker-grey);
  margin-top: 20px;
}

.systemMessageAction {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 14px;

  .systemMessageActionButton {
    font-size: 12px;
    height: 22px;
  }
}

.messageUserName {
  margin-left: 43px;
  margin-bottom: 3px;
  font-size: 11px;
  color: var(--fusend-color-dark-grey);
}

.replyButton {
  --padding-end: 4px;
  --padding-start: 4px;

  &.hide {
    visibility: hidden;
  }
}

.messageReply {
  background: var(--fusend-color-light);
  color: var(--fusend-color-grey-light);
  font-size: 12px;
  text-align: left;
  padding-bottom: 28px;
}

.messageReply+.messageRow {
  margin-top: -20px;
}

.messageTag {
  background-color: var(--ion-color-step-650);
  padding: 0 5px;
  border-radius: 5px;
  cursor: pointer;
}

.otherMessage .messageTag {
  background-color: var(--fusend-color-light);
}

.richChatMessage {
  width: 300px;

  .rideDeleted {
    color: var(--fusend-color-darker-grey);
    text-decoration: line-through;
    font-size: 16px;
  }
}