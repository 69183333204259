.activityCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--fusend-color-background);
  border-bottom: 0.5px solid var(--fusend-color-light);
  padding: 15px;

  .activityCardMain {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    .activityCardPic {
      width: 38px;
      height: 38px;
      margin-right: 10px;

      .activityCardProfilePicture {
        width: 38px;
        height: 38px;
        font-size: 80%;
      }
    }

    .activityCardBody {
      flex-grow: 1;
      font-size: 14px;
      font-weight: 300;
      text-align: start;

      b {
        font-weight: 500;
      }

      .activityCardBodyTimestamp {
        margin-left: 10px;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: var(--fusend-color-grey-lighter);
      }
    }

    .activityCardButtons {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      margin-left: 10px;

      ion-button {
        height: 31px;
        font-size: 14px;
        font-weight: 400;
        --border-radius: 4px;
      }
    }
  }

  .activityCardMessage {
    margin-left: 48px;
    font-size: 14px;
    font-weight: 300;
    color: var(--fusend-color-grey-lighter);
  }
}