.userProfilePage,
.userProfileContent,
.userProfileToolbar {
  --background: var(--fusend-color-background);
}

.userProfileTitle {
  font-size: 18px;
  cursor: pointer;
}

.userProfilePicSection {
  padding-top: 17px;
  position: relative;

}

.userProfileContent {
  .userProfilePrivateInfoSection {
    margin-top: 54px;
  }

  .userProfilePrivateInfoLabel {
    font-size: 15px;
    padding-left: 20px;
  }
}

.userProfilePicture {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 88px;
  height: 88px;
}

.userProfileChangeProfilePicLink {
  text-align: center;
  color: #4792F7;
  font-size: 15px;

  &:active {
    color: var(--fusend-color-text-blue);
  }
}

.userProfileAdvanced {
  .userProfileAdvancedLabel {
    --background: var(--fusend-color-background);
    font-size: 15px;
  }

  .userProfileDeleteButtonContent {
    background-color: var(--fusend-color-background);
    display: flex;
    justify-content: center;

    .userProfileDeleteButton {
      font-size: 15px;
      --background: var(--fusend-color-grey);
    }
  }
}

.userProfileDeletingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  .userProfileDeleting {
    margin-top: 20px;
    zoom: 2;
  }
}


.userProfileDeleteAlert {
  .alert-title {
    font-size: 18px;
  }

  .alert-sub-title {
    color: var(--fusend-color-text-accent)
  }

  .alert-input,
  .alert-message,
  .alert-sub-title {
    font-size: 15px;
    text-align: left;
  }

  .alert-message {
    color: var(--fusend-color-text-primary)
  }

  .alert-button {
    font-size: 15px;

    &.deleteConfirmButton {
      color: var(--fusend-color-text-primary)
    }

    &.deleteCancelButton {
      color: var(--fusend-color-text-accent)
    }
  }
}