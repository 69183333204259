.ProfileHeaderContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  ion-buttons {
    height: 44px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  ion-button {
    width: 80%;
    height: 44px;
    font-size: 17px;
    margin-inline-start: 14px;
    border-radius: 8px;

    &:first-of-type {
      margin-inline-start: 0px;
    }
  }
}

.addFriendModalContent {
  --padding-start: 25px;
  --padding-end: 25px;
  --padding-top: 25px;
}

.profilePicModalAddFriendTextarea {
  --background: var(--fusend-color-grey-text-area);
  text-align: start;
  padding: 0 16px;
  border-radius: 8px;
}

.profilePicModalAddFriendFollow {
  margin-top: 24px;

  ion-checkbox {
    width: 15px;
    height: 15px;
    vertical-align: middle;
    --color: var(--fusend-color-primary);
    --background: var(--fusend-color-primary);
  }

  ion-label {
    margin-left: 10px;
    font-size: 14px;
    color: var(--fusend-color-grey-light)
  }
}

.profilePicModalContentText {
  font-size: 14px;
  color: var(--fusend-color-text-primary);
}

.profilePicModalContentErrMessage {
  position: absolute;
  bottom: -40px;
  color: var(--fusend-color-red);
}

.addFriendButton {
  margin-top: 15px;
  width: 100%;
}