.tripGroupPlaceholder {
    width: 48px;
    height: 48px;
    background-color: rgba(144, 202, 255, 0.2);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tripGroupPlaceholderIcon {
    width: 100%;
    height: 100%;
    padding: 12px;
    color: #90caff;
}