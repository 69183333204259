.chatRoomPage {
  --background: var(--fusend-color-background);
}

.chatRoomContent {
  --background: var(--fusend-color-background);
  --padding-start: 17px;
  --padding-end: 17px;
  --padding-bottom: 10px
}

.chatRoomHeader>ion-toolbar.chatRoomToolbar {
  --background: var(--fusend-color-background);
  --border-width: 0;
  --color: var(--fusend-color-text-primary);
}

.chatRoomTitleText {
  font-size: 18px;
}

.md .chatRoomTitleText {
  padding-inline: 10px;
}

.ios .chatRoomTitleText {
  padding-inline: 75px;
}

.chatRoomTripGroupMenu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.chatRoomTitleIcon {
  font-size: 1.5em;
  margin-right: 10px;
}

.chatRoomInputArea {
  width: 100%;
  bottom: 0;
  background: var(--fusend-color-background);
  display: inline-block;
}

.chatRoomSendButtonContainer {
  flex-grow: 0;
}

.chatRoomSendButton {
  width: 77px;
  height: 38px;
  margin: 0;
  --border-radius: 20px;
  --background: var(--fusend-color-primary);
  --color: var(--fusend-color-white);
}

.chatRoomInputBox {
  height: auto;
  border-radius: 20px;
  --padding-start: 15px;
  --padding-top: 7px;
  --padding-bottom: 7px;
  --padding-end: 15px;
  --color: var(--fusend-color-black);
  --background: var(--fusend-color-background-light);
  line-height: 24px;
  display: inline-block;
  max-height: 250px;
}

.chatRoomInputBox.md {
  margin-top: 0;
}

.chatRoomInputToolbar {
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
  --background: var(--fusend-color-background);
}

.replySection {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.replyContent {
  margin: 5px;
  padding: 7px 15px;
  background-color: var(--fusend-color-light);
  border-radius: 8px;
  display: flex;
  align-items: center;
  color: var(--fusend-color-grey-light);
  font-size: 12px;
}

.replySectionClose {
  --padding-end: 0;
  --padding-start: 0;
}

.taggedUser {
  display: inline-block;

  &:focus {
    background-color: blue;
  }
}

.chatBanner {
  --background: var(--fusend-color-background);
  --padding-start: 18px;
  --inner-padding-top: 6px;
  --inner-padding-bottom: 13px;
  position: sticky;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 100;
}

.chatBannerContent {
  margin-left: 15px;
  font-size: 13px;

  .bannerTitle {
    margin: 0;
  }

  .bannerSubtitle {
    margin: 0;
    font-size: 11px;
    margin-top: 3px;
    color: var(--trip-color-primary);
  }

  .bannerTripDate {
    margin-left: 10px;
  }
}

.bannerBtn {
  --padding-start: 20px;
  --padding-top: 5px;
  --padding-end: 20px;
  --padding-bottom: 5px;
}

.participantProfilePic {
  width: 40px;
  height: 40px;

  &.atAll {
    border-radius: 50%;
    background-color: var(--fusend-color-darker);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.atAllLabel {
  display: flex;
  align-items: center;
}

.chatCardIconContainer {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  .chatCardIcon {
    width: 20px;
    height: 20px;
  }

  &.trip {
    // TODO: use hard code color for now, change to custom icon
    background-color: rgba(135, 198, 138, 0.3);
    color: #87C68A;
  }
}

.chatMoreFunctionBtn {
  font-size: 16px;
  --padding-start: 0;
  --padding-end: 0;
}