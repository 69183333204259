.tripGroupCard {
  display: flex;
  flex-direction: row;
  align-items: center;

  .TripGroupCardPic {
    width: 48px;
  }

  .tripGroupCardBody {
    padding-left: 10px;
    padding-right: 10px;
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;

    .tripGroupCardTitle {
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 26px;
      font-size: 17px;
      line-height: 25.5px;
      font-weight: 500;
    }

    .tripGroupMemberList {
      margin-top: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .tripGroupCardMemberProfileWrapper {
        display: inline-block;

        .tripGroupCardMemberProfile {
          width: 22px;
          height: 22px;
        }
      }

      .tripGroupMemberPlus {
        border-radius: 50%;
        background-color: #2C2C34;
        font-size: 10px;
        line-height: 15px;
        font-weight: 500;
        width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .tripGroupCardChat {
      margin-top: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      color: var(--fusend-color-grey-lighter);

      .tripGroupCardChatMessage {
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;

        .tripGroupCardChatMessageSender {
          font-weight: 500;
        }
      }

      .tripGroupCardChatTimestamp {
        margin-left: 5px;
        width: 62px;
        font-size: 11px;
        line-height: 16.5px;
        font-weight: 400;
        text-align: right;
      }
    }
  }

  .tripGroupCardJoinBtn {
    --background: var(--fusend-color-secondary);
    --background-focused: var(--fusend-color-secondary);
    --background-activated: var(--fusend-color-secondary);
    --background-activated-opacity: 0.5;
    --background-focused-opacity: 0.5;
    color: var(--fusend-color-text-primary);
    height: 32px;
    width: 65px;
  }

  .tripGroupCardForwardChevron {
    margin-left: 20px;
    font-size: 1.5em;
    min-width: 22px;
    max-width: 22px;
  }
}