@import '../page.scss';

.fusendPageToolbar {

  .tripGroupEditorCancelBtn,
  .tripGroupEditorConfirmBtn {
    --color: var(--fusend-color-secondary);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    &[disabled] {
      --color: #9AA5B1;
    }

    &[disabled=false] {
      --color: var(--fusend-color-secondary);
    }
  }
}

.fusendPageContent {
  .createGroupOptions {
    display: flex;
    flex-direction: column;

    .createGroupOptionItem {
      --background: none;
    }

    .createGroupLabel {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--fusend-color-text-accent);
      margin-bottom: 8px;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .createGroupLabelIcon {
        margin-left: 5px;
        font-size: 20px;
      }
    }

    .createGroupValue {
      width: 220px;
      min-width: 220px;
    }

    .createGroupInput {
      font-size: 16px;
      margin-left: 0;
      --padding-start: 0;
    }
  }

  .tripGroupEditorCancelNoMoreToAdd {
    padding: 30% 40px;
    text-align: center;
    color: var(--fusend-color-darker-grey);
  }
}