@import '../page.scss';

.fusendPageTitle {
  .chatRoomDetailTitle {
    width: 100%;
    text-align: center;
    font-size: 18px;
    line-height: 27px;
  }
}

.fusendPageContent {
  .chatRoomDetailTripGroup {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;

    .chatRoomDetailTripGroupMemberList {
      padding: 15px 16px;
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;

      .chatRoomDetailTripGroupMember {
        margin: 5px 0px;
        width: 20%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;

        .chatRoomDetailTripGroupMemberAddLoading {
          width: 56px;
          height: 56px;
        }

        .chatRoomDetailTripGroupMemberAdd {
          width: 56px;
          height: 56px;
          border-radius: 50%;
          border: 1px solid var(--fusend-color-text-primary);
          position: relative;

          div {
            border: .5px solid var(--fusend-color-text-primary);
            position: absolute;

            &.chatRoomDetailTripGroupMemberAddLine1 {
              top: 26px;
              left: 13px;
              width: 26px;
              height: 0px;
            }

            &.chatRoomDetailTripGroupMemberAddLine2 {
              top: 13px;
              left: 26px;
              width: 0px;
              height: 26px;
            }
          }
        }

        .chatRoomDetailTripGroupMemberContainer {
          width: 56px;

          .chatRoomDetailTripGroupMemberPic {
            width: 56px;
            height: 56px;
          }

          .chatRoomDetailTripGroupMemberName {
            margin-top: 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 400;
            font-size: 11px;
            line-height: 16.5px;
            text-align: center;
          }
        }
      }
    }

    .chatRoomDetailTripInfo {
      padding: 15px 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-top: 0.5px solid var(--fusend-color-light);

      .chatRoomDetailTripInfoLabel {
        font-weight: 400;
        size: 17px;
        line-height: 25.5px;
      }

      .chatRoomDetailTripInfoValue {
        font-weight: 500;
        size: 18px;
        line-height: 27px;
        text-align: right;
      }

      .chatRoomDetailTripInfoLabel,
      .chatRoomDetailTripInfoValue {
        &.notEditable {
          color: var(--trip-color-primary);
          cursor: not-allowed;
        }
      }
    }

    .chatRoomDetailTripGroupActions {
      padding: 8px 0px;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      border-top: 0.5px solid var(--fusend-color-light);

      &:last-of-type {
        border-bottom: 0.5px solid var(--fusend-color-light);
      }

      .chatRoomDetailTripGroupActionButton {
        width: 100%;
      }
    }
  }
}