.openBrowserBackdrop {
    display: block;
    list-style-type: none;
    padding: 0;
    overflow: scroll;
}

.openBrowserImageContainer,
.openBrowserOption {
    padding: 20px;
    display: flex;
    align-items: flex-start;

    .h1 {
        font-size: 24px;
        margin: 0;
        padding-right: 12px;
    }

    .important {
        font-size: 20px;
    }
}

.openBrowserIndicatorButton {
    position: fixed;
    right: 10px;
    top: 10px;
    font-size: 80px;
    margin-left: 20px;
    height: 30px;
    width: 30px;
}

.openBrwoserImage {
    padding-top: 20px;
}

.openBrowserImageWithInstruction {
    padding-right: 20px;
}

.openBrowserSad {
    display: flex;
    align-items: center;
    justify-content: center;

    .important {
        font-size: 48px;
    }
}