.fusendPageContent,
.fusendPageToolbar {
  --background: var(--fusend-color-background);
}

.fusendPage {
  .fusendPageHeader {
    display: flex;
    --background: var(--fusend-color-background);

    .fusendPageToolbar {
      display: flex;

      .fusendPageTitle {
        font-size: 20px;
        font-weight: 500;
        color: var(--fusend-color-text-primary);
      }
    }
  }

  .fusendPageFooter {
    background: var(--fusend-color-background);
  }
}