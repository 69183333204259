.carpoolLobbyBody {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .buttonSection {
        display: flex;
        align-items: center;
    }

    .detailsButton {
        pointer-events: none;
    }
}
.detailsSection {
    display: flex;
    width: 100%;
}

.detail {
    flex-grow: 1;

    div {
        text-align: center;
        font-size: 12px;
    }

    .carpoolLobbyContentLineEmphasize {
        color: var(--fusend-color-primary);
        font-size: 20px;

        svg {
            vertical-align: text-top;
            path {
                stroke: #EBBDB6 !important;
            }
        }
    }
}

.homeCarpoolLobby {
    .tripSectionBody {
        padding: 14px 13px 5px 13px;

        .detailsSection {
            padding: 10px 8px;
            background-color: #464F6A;
            border-radius: 8px;
        }
    }

    .detail .carpoolLobbyContentLineEmphasize {
        color: inherit;
        display: inline-block;
        font-size: 15px;

        svg {
            vertical-align: text-top;
            width: 20px;
            height: 20px;
            path {
                stroke: white !important;
            }
        }
    }
}