.video-player {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.video-container {
  flex-grow: 1;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: move;
  touch-action: none;
}

video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}

.custom-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 5px;
}

input[type="range"] {
  cursor: pointer;
}

.playbackSpeed {
  width: 140px;
}

.timeline {
  width: 100%;
}