.tripWrapper {
  background-color: var(--fusend-color-light);
  border-left: 3px solid #728ACD;
  width: 100%;
  padding: 0;
}

.tripTitle {
  color: var(--fusend-color-text-primary);
  margin: 0;
  font-weight: 500;
  flex-grow: 3;
  font-size: 15px;
}

.tripContent {
  color: var(--fusend-color-grey-lighter);
  font-size: 12px;
  margin-top: 0px;
  padding: 0 18px 0 18px;
}

.tripTitleContainer {
  display: flex;
  justify-content: space-between;
  padding: 15px 18px 0 18px;
}

.tripWeather {
  flex-grow: 1;
  text-align: end;
  margin: 0;
  width: 16px;
  height: 16px;

  .iconWrapper svg{
    width: 1.8em;
  }
}

.tripTemp {
  margin: 0;
}

.tripMember {
  margin-top: 10px;

  &.myTripMember {
    font-size: 13px;
  }
}

.myTripBadge {
  margin: 0;
  font-size: 10px;
  padding: 3px 8px;
  margin-left: 10px;
  color: var(--fusend-color-black);
  vertical-align: middle;
  border-radius: 4px;
  font-weight: 550;
  background-color: none;
}

.myTripJoinBadge {
  border: 1px solid var(--fusend-color-primary);
}

.myTripInterestedBadge {
  border: 1px solid #85B0E4;
}

.tripCardProfilePicWrapper {
  display: inline-block;
  vertical-align: bottom;
  margin-left: -3px;

  &:first-of-type {
    margin-left: 8px;
  }
}

.tripCardProfilePic {
  width: 20px;
  height: 20px;

  .fallbackProfilePicTextContainer {
    font-size: 10px;
  }
}

.tripActionBarContainer {
  margin: 13px 20px;
}