.resortFilterModalTitle {
    font-size: 17px;
    font-weight: 500;
    text-align: center;
    width: 100%;
}

.resortFilterToolbar,
.resortFilterContent {
    --background: var(--fusend-color-background);
}

.resortFilterToolbar {
    display: flex;
    background-color: var(--fusend-color-background);
    align-items: center;
    min-height: 56px;
}

.resortFilterFilterContainer {
    margin-bottom: 150px;
}

.resortFilterResortTitle {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
}

.resortFilterStateTitle,
.resortFilterResortOption {
    margin-bottom: 30px;
}

.resortFilterStateTitle {
    font-size: 19px;
    line-height: 20px;
    font-weight: 500;
}

.resortFilterResortOption {
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
}

.resortFilterResortOption,
.resortFilterAllResorts {
    display: flex;
    background: none;
    justify-content: space-between;
    height: 28px;
    font-size: 18px;
    padding: 0;
    --color: var(--fusend-color-black);
    --color-activated: var(--fusend-color-black);
    --color-focused: var(--fusend-color-black);
}

.resortFilterAllResorts {
    font-weight: 500;
}

.resortFilterApplyContainer {
    background-color: var(--fusend-color-background);
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 70px;
    display: flex;
    justify-content: center;
    padding-top: 10px;
}

.resortFilterApplyButton {
    --background: var(--fusend-color-secondary);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 259px;
    height: 40px;
    --color: var(--fusend-color-black);
    border-radius: 4px;
}

.resortFilterErrorMessage {
    color: #E46464;
    font-size: 11px;
    line-height: 16px;
}