.uploadImageBtn {
  width: 100%;
  margin-top: 10px;
  --background: var(--fusend-color-text-blue);
  --background-hover: var(--fusend-color-text-blue);
  --background-focus: var(--fusend-color-text-blue);
  --background-activated: var(--fusend-color-blue);
}

.uploadImageBtnGroup {
  width: 100%;
  padding: 0 25px;
  margin-top: 50px;
}

.cropperContainer {
  height: 400px;
  background: var(--fusend-color-sideMenu-background);
}