.profilePageDivider {
  border-bottom: 1px solid #5D636A;
  margin-top: 32px;
}

.profileUpcomingTripHeader {
  font-weight: 400;
  font-size: 16px;
  text-align: start;
}

.profileHeaderContent {
  text-align: center;
}

.profileNoTripIcon {
  margin-top: 85px;
}

.profileNoTripText {
  margin-top: 5px;
  color: #9AA5B1;
  size: 16px;
}

.profileUpcomingTripItem {
  --background: var(--fusend-color-background);
  --padding-start: 0;
  margin: 14px 0;

  .profileUpcomingTripDate {
    font-size: 14px;
    color: var(--fusend-color-grey-lighter);
    line-height: 22px;
  }

  .profileUpcomingTripTitle {
    font-size: 17px;
    margin-top: 5px;
  }

  ion-col {
    position: relative;
    padding-left: 0;
    padding-right: 16px;
  }
}

.profileUpcomingTripItemIcon {
  font-size: 25px;
  color: var(--fusend-color-primary);
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}