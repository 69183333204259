.save-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 32px;
    padding-bottom: 32px;
}

.save-button {
    background-color: var(--fusend-color-primary);
    border-radius: 12px;
    color: var(--fusend-color-lighter);
    font-size: 20px;
    line-height: 23px;
    font-weight: 500;
    padding: 9px 36px;
}

.save-button:disabled {
    background-color: var(--fusend-color-grey);
}