.friendCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--fusend-color-background);
  padding: 15px;

  .friendCardPic {
    width: 38px;
    height: 38px;
    margin-right: 10px;

    .friendCardProfilePicture {
      width: 38px;
      height: 38px;
      font-size: 80%;
    }
  }

  .friendCardContent {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .friendCardContentFullName {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    .friendCardContentUsername {
      margin-top: 2px;
      color: var(--fusend-color-grey-lighter);
      font-size: 11px;
      font-weight: 300;
      line-height: 16px;
    }

    .friendCardContentTripInfo {
      margin-top: 2px;
      color: var(--fusend-color-primary);
      font-size: 12px;
      font-weight: 300;
      line-height: 18px;
    }
  }

  .friendCardInviteBox {
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      font-size: 16px;
      font-weight: 300;
      line-height: 25px;
      color: var(--fusend-color-grey-lighter);
    }


    .inviteFriendCheckbox {
      margin-left: 10px;
      --background: var(--fusend-color-background);
    }
  }

  .friendCardButtons {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-left: 10px;

    ion-button {
      height: 31px;
      width: 95px;
      font-size: 14px;
      font-weight: 400;
      --border-radius: 4px;
    }
  }
}