.videosContainer {
  /* Limit grid size to parent container */
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  height: 100%;
  display: grid;

  &.twoVideos {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 1rem;

    @media screen and (orientation: portrait) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    }
  }

  &.oneVideo {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
}

.videoContainer {
  display: flex;
  flex-direction: column;
}

.sharedControls {
  position: fixed;
  bottom: 16px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 25px;

  .timeline {
    width: 100%;
  }
}

.uploadControl {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}