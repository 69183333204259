$mbsc-ios-background: #2C2C34;
$mbsc-ios-calendar-background: #2C2C34;
$mbsc-ios-calendar-accent: #EBBDB6;
@import '../../../node_modules/@mobiscroll/react/dist/css/mobiscroll.scss';

.homePageWrapper {
  height: 100%;
}

.mbsc-list-item:after {
  border: none !important;
}

.mbsc-list-item:before {
  border: none !important;
}

.mbsc-calendar {
  padding-bottom: 50px;
}

.calendarNavBtns {
  right: 10px;
}

.calendarHeaderWrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;

  .mbsc-segmented {
    margin: 0;
    background-color: #2C2C34 !important;
  }

  .mbsc-segmented-item {
    background-color: #2C2C34;

    .mbsc-selected {
      background-color: var(--fusend-color-light);
    }
  }
}

@keyframes loadingAnimation {
  from {
    opacity: 0.2;
  }

  50% {
    opacity: 0.38;
  }

  to {
    opacity: 0.2;
  }
}

.TripLoaderWrapper {
  height: 125px;
  width: 100%;
  background: #999999;
  animation: loadingAnimation 1.7s infinite;
}

.homePageWrapper {
  --background: var(--fusend-color-background);

  div.mbsc-event-day.mbsc-list-header {
    background-color: var(--fusend-color-background);
    border-bottom: none;
    border-top: none;
    font-size: 13px;
    font-weight: 400;
    padding-bottom: 3px;
  }

  div.mbsc-calendar {
    background-color: var(--fusend-color-background);
  }

  div.mbsc-event.mbsc-list-item {
    background-color: var(--fusend-color-background);
    padding-bottom: 6px;
    padding-top: 6px;
  }

  div.mbsc-calendar-controls,
  .mbsc-calendar-wrapper {
    background-color: #2C2C34;
    border-bottom: none !important;
  }

  .mbsc-eventcalendar{
    .mbsc-event-list {
      .mbsc-list {
        padding-bottom: 170px;

        .mbsc-list-item-background {
          background: none;
        }
      }
    }
  }

  .mbsc-eventcalendar div.mbsc-calendar-week-days {
    background-color: #2C2C34;
  }

  .mbsc-calendar-cell {
    border: none;

    &.mbsc-selected .mbsc-calendar-cell-text {
      border-radius: 9px;
    }
  }

  .homePageHeader {
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 3px;
    padding-bottom: 2px;
    background-color: #2C2C34;

    .homePageToolbar {
      --background: #2C2C34;
      --border-width: 0px;
    }

    .icons {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .iconButton {
        margin: 0;
        width: 32px;
        height: 40px;
        --padding-bottom: 0px;
        --padding-top: 0px;
        --padding-start: 0px;
        --padding-end: 0px;
        margin-left: 7px;

        .iconBadge {
          position: absolute;
          top: 0;
          right: 0;
          // bottom: 0;
          zoom: 0.8;
          color: var(--fusend-color-text-primary);
        }

        .icon {
          color: var(--ion-color-dark);
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .homeHeaderProfilePic {
    width: 32px;
    height: 32px;
    font-size: 80%;
  }

  .homeHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 5px;
    cursor: pointer;

    .homeHeaderLogo {
      height: 27px;
    }

    .homeHeaderCommunity {
      padding: 2px;
      font-size: 15px;
      color: var(--fusend-color-text-primary);

      &.heartbeat {
        animation: heartbeat 1.5s infinite;
      }

      @keyframes heartbeat {
        0% {
          transform: scale(0.9);
        }
        50% {
          transform: scale(1);
        }
        100% {
          transform: scale(0.9);
        }
      }
    }
  }

}

.HomePageCalender {
  padding-bottom: 0;
  height: auto;
}

.HomePageAgenda {
  .mbsc-event-list {
    padding-bottom: 100px;
  }
}

.HomePageFilterToggleContainer {
  padding: 0 17px;
  height: 45px;
  justify-content: space-between;

  ion-button.HomePageFilterToggle {
    --border-radius: 10px;
    margin-inline-start: 5px;
    margin-inline-end: 5px;
    font-size: 13px;
    height: 27px;
    --padding-top: 6px;
    --padding-bottom: 6px;
    --padding-start: 14px;
    --padding-end: 14px;

    &:first-of-type {
      margin-inline-start: 0px;
    }
  }
}

.mbsc-calendar-marks>.mbsc-calendar-mark {
  &.goingMark {
    border: solid 1px #EBBDB6;
    background: none;
  }

  &.interestedMark {
    border: solid 1px #85B0E4;
    background: none;
  }
}

.communitySelectorBtn {
  margin-left: -7px;
}

.homeHeaderCommunity {
  color: white;
  span {
    display: inline-block;
    font-size: 1rem;
    width: 180px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  ion-icon {
    vertical-align: middle;
  }
}