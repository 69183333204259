.tripDetailContent {
  --padding-start: 18px;
  --padding-end: 18px;
  --padding-bottom: 50px;
}

.tripDetailTitle {
  font-size: 23px;
  margin-top: 10px;
}

.tripDetailSectionTitle {
  position: relative;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  margin-top: 20px;
}

.tripDetailMyTripProfileSection {
  .tripDetailSectionTitle {
    margin-top: 18px;
  }
}

.tripDetailUserProfile {
  width: 40px;
  height: 40px;
}

.tripDetailUserProfileWrapper {
  display: inline-block;
  margin: 0 2px;
  vertical-align: middle;

  &:first-of-type {
    margin-left: 0;
  }
}

.tripDetailParticipantProfilePics {
  margin-bottom: 15px;
  line-height: 60px;
  width: 95%;
  overflow: scroll;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }
}

.myTripProfileBody {
  background-color: var(--fusend-color-light);
}

.tripSectionBody,
.tripGroupCard {
  width: 100%;
  min-height: 50px;
  background-color: var(--fusend-color-light);
  border-radius: 10px;
  padding: 15px 13px;
}

.tripGroupCard {
  margin-top: 15px;
  height: 85px;
  padding: 10px 13px;

  &:first-child {
    margin-top: 0;
  }
}

.weatherBody {
  & .summary>.iconWrapper svg {
    width: 45px;
    height: 45px;
  }

  & .summary>.temperature {
    font-size: 35px;
    font-weight: 500;
    margin-left: 12px;
    vertical-align: top;
  }
}

.currentHourWrapper {
  display: flex;

  & .summary {
    width: 50%;

    .iconWrapper svg {
      width: 3.5em;
      height: 3.5em;
    }
  }

  & .weatherCode {
    width: 50%;
    padding-left: 20px;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    position: relative;

    &.md {
      font-size: 17px;
    }

    &.sm {
      font-size: 14px;
    }

    .weatherCodeText {
      position: relative;
      top: 50%;
      display: block;
      transform: translateY(-50%);
    }
  }
}

.hourlyWeatherWrapper {
  text-align: center;
  display: inline-block;
  font-size: 12px;
  padding: 10px;

  .iconWrapper svg {
    width: 2.5em;
    height: 2.5em;
  }

  &:first-of-type {
    padding-left: 0;
  }
}

.weatherByHour {
  overflow-x: scroll;
  scrollbar-width: none;
  margin-top: 15px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.weatherByHourStrip {
  white-space: nowrap;
}

.emptyWrapper {
  text-align: center;
  font-weight: 400;
  color: #7B8794;
  margin-top: 0px;

  img {
    height: 20px;
  }

  span {
    font-size: 14px;
  }
}

.tripDetailViewAllBtn {
  font-size: 14px;
  vertical-align: middle;
  position: absolute;
  right: 0;
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--fusend-color-primary);
}

.participantsTabBar {
  width: 100%;

  .participantsTab {
    height: 50px;
    width: 50%;
    color: var(--fusend-color-black);

    &.activeTab {
      border-bottom: solid 1px;
    }
  }
}

.participantProfilePic {
  width: 40px;
  height: 40px;
}

.participantRow {
  padding: 11px;
  width: 100%;
}

.participantUsername {
  margin: 0;
  font-size: 11px;
  color: var(--trip-color-primary);
}

.participantItem {
  --background: var(--fusend-color-background);
}

.tripDetailTitleSection {
  width: 100%;
  margin-top: 15px;
}

.emptyGroupIcon {
  font-size: 30px;
}

.groupTitle {
  display: inline-block;
}

.groupCreateBtn {
  float: right;
  --color: var(--fusend-color-secondary);
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;

  .groupCreateBtnIcon {
    margin-right: 5px;
  }
}

.tripGroupThumbnail {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;

  &.carpoolThumbnail {
    background-color: rgba(144, 202, 255, 0.2);
  }

  .tripGroupThumbnailImg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.tripGroupParticipantCard {
  --background: none;
  --inner-padding-top: 15px;
  --inner-padding-bottom: 15px;

  ion-checkbox {
    --background: none;
  }
}

.createGroupButton {
  width: 60%;
}

.tripGroupParticipantCardProfilePicture {
  width: 38px;
  height: 38px;
  font-size: 80%;
}

.tripGroupParticipantCardContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 26px;

  .tripGroupParticipantCardFullName {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .tripGroupParticipantCardUsername {
    margin-top: 2px;
    color: var(--fusend-color-grey-lighter);
    font-size: 11px;
    font-weight: 300;
    line-height: 16px;
  }
}

.createGroupFooter {
  padding: 10px 0;
}

.tripGroupThumbnailSingleProfilePic {
  width: 48px;
  height: 48px;
}

.tripGroupThumbnailDoubleProfilePicWrapper {
  position: absolute;

  .tripGroupThumbnailDoubleProfilePic {
    width: 28px;
    height: 28px;
  }

  &:first-of-type {
    left: 2px;
    top: 2px;
  }

  &:last-of-type {
    bottom: 2px;
    right: 2px;
  }

}

.roadConditionBody {
  padding: 15px 13px;
}

.roadConditionWrapper {
  .roadName {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  .directionCondition {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }

  .direction,
  .condition {
    font-size: 14px;
    line-height: 20px;
  }

  .direction {
    font-weight: 500;
  }

  .condition {
    margin-left: 22px;
    white-space: pre-wrap;
  }

  .roadConditionUpdateTime {
    font-size: 8px;
    color: var(--trip-color-primary);
    margin-top: 8px;
  }
}

.tripDetailTitleSection {
  display: flex;
  justify-content: space-between;

  .addButton {
    margin-top: 20px;
  }
}