.communityInfoPage {

  .communityInfoToolbar,
  .communityInfoContent {
    --background: var(--fusend-color-background);
  }

  .communityInfoToolbar {
    display: flex;
    background-color: var(--fusend-color-background);
    align-items: center;
    min-height: 56px;

    .communityInfoToolbarButtons {
      width: 60px;
    }

    .communityInfoToolbarTitle {
      font-size: 17px;
      font-weight: 500;
      text-align: center;
      width: 100%;
    }
  }

  .communityEntry {
    margin-top: 10px;
    background: var(--fusend-color-light);
    padding: 20px 20px;
    text-align: center;
    border-radius: 10px;

    .communityEntryName {
      font-weight: 600;
      font-size: 24px;
    }

    .communityEntryUserCount {
      margin-top: 5px;
      color: #ccc;
    }

    .communityEntryJoinButton {
      --background: var(--fusend-color-secondary);
    }

    ion-button {
      margin-top: 25px;
      margin-left: 50%;
      transform: translateX(-50%);
      width: 55%;
      height: 37px;
    }
    .button-disabled {
      opacity: 1;
    }

  }
}