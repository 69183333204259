.carpoolLobbyPage {
    --padding-start: 18px;
    --padding-end: 18px;
    --padding-bottom: 50px;
}

.carpoolLobbySection {
    margin: 20px 0;

    .carpoolLobbyTitleWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .carpoolLobbyTitle {
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
    }

    .previews {
        min-height: 50px;
        border-radius: 10px;
        margin-top: 20px;
    }
}

.intentCard,
.carpoolCard {
    align-items: center;
    --background: var(--fusend-color-light);
    --padding-top: 13px;
    --padding-bottom: 13px;
    --padding-start: 16px;
    --padding-end: 16px;
    --border-radius: 8px;
    margin-bottom: 10px;
    --border-width: 0;
    --inner-border-width: 0;

}

.chatCardTitle {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;

    .roleLabel {
        display: flex;
        flex-direction: row;
        align-items: center;

        .roleLabelIcon {
            height: 18px;
            margin-right: 2px;
        }
    }
}

.intentCard {
    .intentCardDetails {
        width: 100%;
    }

    .userCard {
        display: flex;
        align-items: start;
        justify-content: space-between;

        .profilePicWrapper .profilePicture {
            width: 40px;
            height: 40px;
        }
    }

    .userInfo {
        display: flex;
        align-items: center;
    }

    .userCardInfo {
        margin-left: 12px;
    }

    .userFullName,
    .note {
        color: var(--fusend-color-text-primary);
    }

    .username {
        color: var(--fusend-color-grey);
        font-size: 12px;
    }

    .noteContainer {
        display: flex;
        align-items: center;
    }

    .note {
        margin-top: 10px;
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        max-height: 1.8em;
        line-height: 1.8em;
    }
}

.carpoolCard {
    .carpoolCardContent {
        width: 100%;
    }

    .carpoolCardTitle {
        font-weight: 500;
        font-size: 17px;
        line-height: 26px;
    }

    .carpoolCardNotes {
        font-weight: 300;
        font-size: 13px;
        line-height: 20px;
        color: var(--fusend-color-grey);
        margin-top: 6px;
    }

    .profilePicWrapper {
        display: inline-block;
        margin-left: 4px;
        vertical-align: bottom;

        &:first-of-type {
            margin-left: 0;
        }

        .profilePicture {
            width: 24px;
            height: 24px;
        }
    }

    .carpoolCardSpotLeft {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        margin-left: 8px;
    }

    .emptySpot {
        width: 24px;
        height: 24px;
        display: inline-block;
        vertical-align: bottom;
        border-radius: 50%;
        background-color: #464F6A;
        margin-left: 4px;
    }

    .carpoolCardTitleSection {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .carpoolLabelGroup {
        display: flex;
    }
}

.newCarpoolBtn {
    width: 100%;
    margin-top: 27px;

    span {
        margin-left: 5px;
    }
}

.mineLabel,
.fullLabel {
    font-size: 9px;
    padding: 2px 5px;
    border-radius: 4px;
    margin-top: 4px;
    margin-left: 5px;
}

.mineLabel {
    border: solid 1px var(--fusend-color-primary);
}

.fullLabel {
    border: solid 1px var(--trip-color-submit-pressed);
}