.createIntentSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 0;

    .createIntentOptionItem {
      --background: none;
      width: 100%;
    }

    .createIntentLabel {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--fusend-color-text-accent);
      margin-bottom: 8px;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .createIntentLabelIcon {
        margin-left: 5px;
        font-size: 20px;
      }
    }

    .createIntentValue {
      width: 220px;
      min-width: 220px;
    }

    .createIntentInput {
      font-size: 16px;
      margin-left: 0;
      --padding-start: 0;
    }
  }

.intentInformation {
    padding: 7px 0;
    text-align: center;

    div {
        margin-top: 10px;
    }
}

.createIntentButton {
    margin-top: 24px;
    width: 180px;
}

.createIntentError {
    color: var(--fusend-color-red);
    font-size: 11px;
    font-weight: 400;
    line-height: 16.5px;
    margin-top: 4px;
}