.googleLoginContainer {
    position: relative;
    width: 100%;
    max-width: 350px;
    cursor: pointer;
}

.googleLoginButtonOverlay {
    position: relative;
    pointer-events: none;
    width: 100%;
    max-width: 350px;
    height: 35px;
    --border-radius: 24px;
    position: relative;
    font-weight: 400;
    font-size: 15px;
    color: var(--fusend-color-text-primary);
    --background: var(--fusend-color-light);
    --background-activated: var(--fusend-color-light);
    --background-focused: var(--fusend-color-light);
    --background-hover: var(--fusend-color-light);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.googleWebLogin {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginLogo {
    width: 22px;
}

.loginLogoFiller {
    width: 22px;
    height: 22px;
}

.buttonContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}