.logoImage {
  position: absolute;
  top: 40vh;
  left: 50%;
  width: 85%;
  max-width: 350px;
  transform: translate(-50%, -50%);
}

.loginActions {
  position: absolute;
  width: 100%;
  top: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginActions .loadingText {
  font-weight: 400;
  font-size: 15px;
  color: var(--fusend-color-white);
}

.loginActions .loadingSpinner {
  margin-top: 10px;
  color: var(--fusend-color-white);
}

.loginButton {
  width: 100%;
  max-width: 350px;
  height: 35px;
  --border-radius: 24px;
  position: relative;
  font-weight: 400;
  font-size: 15px;
  color: var(--fusend-color-dark-grey);
  --background: var(--fusend-color-white);
  --background-activated: var(--fusend-color-lighter);
  --background-focused: var(--fusend-color-white);
  --background-hover: var(--fusend-color-white);
}

.buttonContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.loginLogo {
  width: 22px;
}

.loginLogoFiller {
  width: 22px;
  height: 22px;
}

.loginLogo.apple.dark {
  filter: invert(1);
}

.tos {
  color: var(--ion-color-primary-contrast);
}

.tos a,
.tos a:visited {
  color: var(--ion-color-primary);
  text-decoration: underline;
}

.loginError {
  font-size: 14px;
  font-weight: 500;
  color: var(--fusend-color-red);
  cursor: pointer;
}

.tos,
.loginError {
  padding: 15px 15px 0 15px;
  font-size: 12px;
}