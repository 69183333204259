@media (min-width:1281px) {
  .appRoot {
    position: fixed;
    width: 1080px;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.appVersionTooLow {
  position: absolute;
  width: 100%;
  top: 55vh;

  font-size: 14px;
  color: var(--fusend-color-text-primary);
  text-align: center;

  .appVersionTooLowAppLink {
    text-decoration: underline;
  }
}