.intentSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;

    .userInfo {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: var(--fusend-color-text-primary);
    }

    .nameAndUsername {
        margin-left: 16px;
    }
    .name {
        font-size: 20px;
        font-weight: 500;
    }

    .username {
        font-size: 14px;
        font-weight: 300;
        line-height: 18px;
        color: var(--trip-color-dark);
    }

    .intentInfo {
        margin-top: 50px;
        width: 100%;
        text-align: center;
        white-space: pre-line;
        line-height: 40px;
    }

    .intentEditButton {
        margin-top: 0;
    }

    .intentEditTextarea {
        --background: var(--fusend-color-grey-text-area);
        padding: 5px 15px;
        border-radius: 8px;
        margin-bottom: 10px;
        line-height: normal;
        text-align: start;
    }

    .intentActions {
        display: flex;
        flex-direction: column;
        margin-top: 70px;
    }

    .intentActionButton {
        margin: 12px 0;
        width: 180px;
    }

    .editable-value-container {
        width: 100%;
        flex-shrink: 0;
    }

    .intentDetailProfile {
        width: 64px;
        height: 64px;
    }

    .nonEditableNote {
        color: var(--trip-color-primary);
    }
}