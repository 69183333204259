.chatPageContainer {
  --background: var(--fusend-color-background);
}

.chatHeaderContainer {
  --background: var(--fusend-color-background);
}

.chatToolBar {
  padding: 5px 0;
  --background: var(--fusend-color-background);
  --color: var(--fusend-color-text-primary);
}

.chatToobarTitle {
  font-weight: 600;
  font-size: 20px;
}

.chat {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
}

.chatListContent {
  --background: var(--fusend-color-background);
}

ion-list.chatList {
  background: none;
}

.chatListEmpty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: var(--fusend-color-darker-grey);
  min-height: 100%;
  text-align: center;
}

.chatListEmptyIcon {
  width: 46px;
  margin: 8px 0;
}

.chatListEmptyMessageMain {
  font-size: 16px;
  font-weight: 500;
  margin: 8px 0;
}

.chatListEmptyMessageSecondary {
  font-size: 14px;
  margin: 8px 0;
}

.chatPageBackBtn {
  --color: var(--fusend-color-text-primary);
}

.chatListContainer.md {
  padding: 0;
}