@font-face {
  font-family: 'Poppins';
  src: url(./font/poppins/Poppins-Thin.ttf);
  font-weight: 100;
}

@font-face {
  font-family: 'Poppins';
  src: url(./font/poppins/Poppins-ThinItalic.ttf);
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url(./font/poppins/Poppins-ExtraLight.ttf);
  font-weight: 200;
}

@font-face {
  font-family: 'Poppins';
  src: url(./font/poppins/Poppins-ExtraLightItalic.ttf);
  font-weight: 200;
  font-style: italic;
}


@font-face {
  font-family: 'Poppins';
  src: url(./font/poppins/Poppins-Light.ttf);
  font-weight: 300;
}

@font-face {
  font-family: 'Poppins';
  src: url(./font/poppins/Poppins-LightItalic.ttf);
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url(./font/poppins/Poppins-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: 'Poppins';
  src: url(./font/poppins/Poppins-Italic.ttf);
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url(./font/poppins/Poppins-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: 'Poppins';
  src: url(./font/poppins/Poppins-MediumItalic.ttf);
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url(./font/poppins/Poppins-SemiBold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: 'Poppins';
  src: url(./font/poppins/Poppins-SemiBoldItalic.ttf);
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url(./font/poppins/Poppins-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: 'Poppins';
  src: url(./font/poppins/Poppins-BoldItalic.ttf);
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url(./font/poppins/Poppins-ExtraBold.ttf);
  font-weight: 800;
}

@font-face {
  font-family: 'Poppins';
  src: url(./font/poppins/Poppins-ExtraBoldItalic.ttf);
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url(./font/poppins/Poppins-Black.ttf);
  font-weight: 900;
}

@font-face {
  font-family: 'Poppins';
  src: url(./font/poppins/Poppins-BlackItalic.ttf);
  font-weight: 900;
  font-style: italic;
}

:root {
  --ion-font-family: 'Poppins', sans-serif;
  font-family: 'Poppins', sans-serif;
}