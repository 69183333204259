.sideMenuHeaderContainer {
  padding: 65px 0 20px 0;
  position: relative;
  border-bottom: 0.6px solid #5D636A;
  display: flex;
  flex-direction: column;
  align-items: center;

  .sideMenuHeaderPicture {
    height: 89px;
    width: 89px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .qrIconWrapper {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: var(--fusend-color-primary);
      position: absolute;
      right: -9px;
      bottom: 2px;
      box-shadow: 0px 0px 5px 0px #5D636A;

      ion-icon {
        width: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .sideMenuHeaderTitle {
    padding: 5px;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
  }

  .sideMenuHeaderSubtitle {
    text-align: center;
    font-weight: 4300;
    font-size: 14px;
    line-height: 21px;
    color: var(--fusend-color-grey-lighter);
  }

  .sideMenuHeaderFriendInfo {
    display: flex;
    font-size: 14px;
    line-height: 21px;
    cursor: pointer;

    .sideMenuHeaderFriendInfoLabel {
      color: var(--fusend-color-grey-lighter);
      margin-left: 5px;
    }
  }
}

.sideMenuProfilePic {
  .fallbackProfilePicTextContainer {
    font-size: 1.5em;
  }
}

.sideMenuContainer,
.sideMenuHeader,
.sideMenuToolbar {
  --background: var(--fusend-color-sideMenu-background);
}

.nobackground {
  --background: none;
  background: none;
}

.sideMenuVersionString {
  position: absolute;
  bottom: 15px;
  font-size: 14px;
  line-height: 21px;
  color: var(--fusend-color-grey-lighter);
  margin-top: 10px;
  text-align: center;
  width: 100%;
}