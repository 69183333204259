.signUpPage {
    --background: linear-gradient(180deg, #121314 0%, #30445F 100%);
}

.editableContent {
    padding-bottom: 172px;
    overflow: auto;
}

.title {
    font-weight: 400;
    font-size: 26px;
    text-align: center;
    padding-top: 48px;
}

.subtitle {
    font-weight: 400;
    font-size: 15px;
    text-align: center;
}

.editableDetails {
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.submit {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 92px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #29394E;
    box-shadow: 0px -5px 8px 0px rgba(0, 0, 0, 0.4);
}