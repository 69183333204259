.fallbackProfilePic {
  width: 20vw;
  height: 20vw;
  max-width: 90px;
  max-height: 90px;
  background-color: var(--fusend-color-primary);
  color: var(--fusend-color-white);
  position: relative;
  border-radius: 50%;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fallbackProfilePicTextContainer {
  font-size: 1em;
}
