.profileImg {
  width: 100%;
}

.profilePicture {
  width: 20vw;
  height: 20vw;
  max-width: 90px;
  max-height: 90px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  margin: 0;
}