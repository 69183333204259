.jerryContainer {
  position: absolute;
  width: 100%;
  top: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.jerryText {
  font-size: 14px;
  color: var(--ion-color-primary-contrast);
}

.jerryGoggles {
  font-size: 20px;
  margin-top: 10px;
  height: 80px;
}

.jerryVersion {
  font-size: 14px;
  color: var(--fusend-color-grey-lighter);
}