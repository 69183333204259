.userProfileEditableItem {
  --background: none;
  --inner-border-width: 0;
  --padding-top: 10px;

  ion-col {
    padding-left: 0;
  }

  .userProfileEditableLabel {
    font-size: 16px;
    padding: 10px 0;
    --color: #9AA5B1;
  }

  .userProfileEditableRow {
    width: 100%;
  }

  .userProfileEditableSelect {
    max-width: 100%;
  }
}


.userProfileEditableInput,
.userProfileEditableSelect {
  --padding-start: 0px;
  --padding-end: 15px;
}

.userProfileEditableInput {
  &:last-child {
    --inner-padding-start: 0;
  }
}

.userProfileEditableReadonly {
  margin: 0;
  padding: 10px 0;
}

.userProfileEditableInput {
  margin: 0;
}

.userProfileEditableRowError {
  color: var(--fusend-color-red);
  font-size: 11px;
  font-weight: 400;
  line-height: 16.5px;
  margin-top: 4px;
}