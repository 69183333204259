.chatCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--fusend-color-background);
  border-bottom: 0.5px solid var(--fusend-color-light);
  padding: 23px 20px 26px 16px;

  .chatCardPic {
    width: 52px;
    height: 52px;
    margin-right: 10px;

    .chatCardProfilePicture {
      width: 52px;
      height: 52px;

      .fallbackProfilePicTextContainer {
        font-size: 1.5em;
      }
    }

    .chatCardIconContainer {
      border-radius: 50%;
      width: 52px;
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: center;

      .chatCardIcon {
        width: 28px;
        height: 28px;
      }

      &.ride {
        // TODO: use hard code color for now, change to custom icon
        background-color: rgba(144, 202, 255, 0.3);
        color: #90CAFF;
      }

      &.trip {
        // TODO: use hard code color for now, change to custom icon
        background-color: rgba(135, 198, 138, 0.3);
        color: #87C68A;
      }
    }
  }

  .chatCardBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    flex-grow: 1;
    overflow: hidden;

    .chatCardTitle {
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 17px;
      font-weight: 400;
      line-height: 26px;
      color: var(--fusend-color-text-primary);
    }

    .chatCardSubtitle {
      margin-top: 2px;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      color: var(--fusend-color-text-secondary);
    }

    .chatCardMessage {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      width: 100%;

      .chatCardMessageContent {
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        color: var(--fusend-color-grey-lighter);
      }

      .chatCardMessageTime {
        text-align: right;
        white-space: nowrap;
        margin-left: 15px;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: var(--fusend-color-grey-lighter);
      }
    }
  }

  .chatCardBody.unread {
    .chatCardTitle {
      font-weight: 500;
    }

    .chatCardMessage {
      .chatCardMessageContent {
        color: var(--fusend-color-text-primary);
        font-weight: 500;
      }
    }
  }

  .chatCardUnread {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 30px;

    .chatCardUnreadDot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: var(--fusend-color-primary);
      color: var(--fusend-color-background);
    }
  }
}