@import '../page.scss';

.friendPage {
  .fusendPageContent {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .friendPageContent {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    .friendPageContentFriendList {
      width: 100%;
    }

    .friendPageContentNoFriend {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding-top: 100px;
      color: var(--fusend-color-grey-lighter);
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;

      ion-button {
        height: 31px;
        font-size: 14px;
        font-weight: 400;
        --border-radius: 4px;
      }
    }

    &.empty {
      padding-top: 120px;

      span {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: var(--fusend-color-grey-lighter);
        max-width: 297px;
        text-align: center;
      }

      .friendDiscoverPeopleButton {
        margin-top: 14px;
        font-size: 15px;
        line-height: 22.5px;
      }
    }
  }

  .fusendPageFooter {
    height: 109px;
    border-top: 1px solid var(--fusend-color-light);
    padding-top: 19px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .friendInviteButton {
      --border-radius: 4px;
      width: 264px;
    }
  }
}

.friendPageSearchBar {
  --background: var(--ion-color-step-250);
}