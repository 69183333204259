@import '../page.scss';

.requestToJoinContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px 0px;
  text-align: center;

  .emptySpot {
    width: 32px;
    height: 32px;
    display: inline-block;
    vertical-align: bottom;
    border-radius: 50%;
    background-color: #464F6A;
    margin-left: 4px;
  }

  .requestToJoinTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    font-size: 20;

    .requestToJoinTitleIcon {
      border-radius: 50%;
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--fusend-color-grey);
    }

    span {
      margin-left: 10px;
      font-size: 22px;
      font-weight: 400;
    }
  }

  .requestToJoinParticipantsSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;

    .requestToJoinMembersSection {
      display: flex;
      flex-direction: row;

      .requestToJoinMemberProfile {
        width: 32px;
        height: 32px;
        margin-right: 5px;
      }
    }

    .requestToJoinCarpoolSpotLeft {
      margin-left: 28px;
      font-weight: 400;
      font-size: 14px;
    }
  }

  .requestToJoinCarpoolNotes {
    margin-top: 55px;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    max-height: 100px;
    color: var(--trip-color-primary);
  }

  .actionSection {
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .actionButton {
    margin: 12px 0;
    width: 180px;
  }
}