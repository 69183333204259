.roleSelectionSection {
    padding: 36px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.roleAction {
    margin-top: 24px;

    svg {
        width: 18px;
        vertical-align: text-bottom;
        margin-right: 11px;
    }
}