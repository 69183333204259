/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #EBBDB6;
  --ion-color-primary-rgb: 152, 154, 162;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #d6aba5;
  --ion-color-primary-tint: #d3bdba;

  --ion-color-secondary: #376FED;
  --ion-color-secondary-rgb: 152, 154, 162;
  --ion-color-secondary-contrast: #FFFFFF;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #7ea3ce;
  --ion-color-secondary-tint: #7897bc;


  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** fusend theme color **/
  --fusend-color-lighter: #F1F6FB;
  --fusend-color-light: #C8E1FF;
  --fusend-color-background-light: #DDEAF3;
  --fusend-color-primary: #98BFEF;
  --fusend-color-dark: #577399;
  --fusend-color-darker: #475973;

  /** fusend mono color **/
  --fusend-color-white: #FFFFFF;
  --fusend-color-grey-light: #F4F4F4;
  --fusend-color-grey: #C6CEDA;
  --fusend-color-dark-grey: #757575;
  --fusend-color-darker-grey: #999999;
  --fusend-color-black: #1F1F1F;

  --fusend-color-tab-inactive: #999999;

  /** fusend other colors **/
  --fusend-color-green: #79D37C;
  --fusend-color-faded-green: #87C68A;
  --fusend-color-red: #E46464;
  --fusend-color-faded-red: #F2A9A9
}

@media (prefers-color-scheme: dark),
(prefers-color-scheme: light) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #EBBDB6;
    --ion-color-primary-rgb: 152, 154, 162;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #d6aba5;
    --ion-color-primary-tint: #d3bdba;

    --ion-color-secondary: #376FED;
    --ion-color-secondary-rgb: 152, 154, 162;
    --ion-color-secondary-contrast: #FFFFFF;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #7ea3ce;
    --ion-color-secondary-tint: #7897bc;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;

    /** fusend theme color **/
    --fusend-color-lighter: #1F2933;
    --fusend-color-light: #343B4E;
    --fusend-color-background: #2C2C34;
    --fusend-color-background-light: #3E4C59;
    --fusend-color-primary: #EBBDB6;
    --fusend-color-secondary: #376FED;
    --fusend-color-dark: #ebbdb6;
    --fusend-color-text-blue: #85B0E4;

    --fusend-color-text-primary: #E4E7EB;
    --fusend-color-text-secondary: #85B0E4;
    --fusend-color-text-accent: #EBBDB6;

    --fusend-color-tab-inactive: #CBD2D9;

    /** fusend mono color **/
    --fusend-color-white: #1F1F1F;
    --fusend-color-grey-lighter: #8F9BB3;
    --fusend-color-grey-light: #999999;
    --fusend-color-grey: #757575;
    --fusend-color-dark-grey: #C6CEDA;
    --fusend-color-black: #FFFFFF;

    --fusend-color-green: #79D37C;
    --fusend-color-faded-green: #87C68A;
    --fusend-color-red: #E46464;
    --fusend-color-faded-red: #F2A9A9;
    --fusend-color-blue: #90CAFF;

    /** fusend trip color **/
    --trip-color-primary: #9AA5B1;
    --trip-color-dark: #E4E7EB;
    --trip-color-light: #2C2C34;
    --trip-color-submit: #3D74BB;
    --trip-color-submit-pressed: #1c7af3;
    --trip-color-submit-disabled: #757A81;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;

    /** fusend theme color **/
    --fusend-color-lighter: #1F2933;
    --fusend-color-light: #343B4E;
    --fusend-color-background-light: #3E4C59;
    --fusend-color-primary: #EBBDB6;
    --fusend-color-dark: #ebbdb6;
    --fusend-color-sideMenu-background: #2C2C34;

    --fusend-color-text-primary: #E4E7EB;
    --fusend-color-text-accent: #EBBDB6;

    --fusend-color-tab-inactive: #CBD2D9;

    /** fusend mono color **/
    --fusend-color-white: #1F2933;
    --fusend-color-grey-light: #999999;
    --fusend-color-grey: #757575;
    --fusend-color-grey-text-area: #4B4B58;
    --fusend-color-dark-grey: #C6CEDA;
    --fusend-color-black: #FFFFFF;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;

    /** fusend theme color **/
    --fusend-color-lighter: #1F2933;
    --fusend-color-light: #343B4E;
    --fusend-color-background-light: #3E4C59;
    --fusend-color-primary: #EBBDB6;
    --fusend-color-dark: #ebbdb6;
    --fusend-color-sideMenu-background: #2C2C34;

    --fusend-color-text-primary: #E4E7EB;
    --fusend-color-text-accent: #EBBDB6;

    --fusend-color-tab-inactive: #CBD2D9;

    /** fusend mono color **/
    --fusend-color-white: #1F2933;
    --fusend-color-grey-light: #999999;
    --fusend-color-grey: #757575;
    --fusend-color-grey-text-area: #4B4B58;
    --fusend-color-dark-grey: #C6CEDA;
    --fusend-color-black: #FFFFFF;
  }

  .md ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }
}