.logoPage {
  padding: 0 12.5%;
  width: 100%;
  height: 100%;
  position: relative;
  background: linear-gradient(180deg, #121314 0%, #30445F 100%);;
}

.logoContent {
  --background: none;
}