.debugSection {
    padding: 10px 16px;
}

.sessionIdSection,
.errorSection,
.warningSection {
    padding: 10px 0;
}

.errorLabel,
.warningLabel,
.sessionIdLabel {
    font-size: 16px;
    font-weight: 600;
}

.sessionIdCopyBtn {
    padding: 10px 16px;
}

.sessionIdValueSection {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}