
.discoverPeopleContent,
.discoverPeopleHeaderContainer,
.discoverPeopleToolBar{
  --background: var(--fusend-color-background);

  ion-list {
    background: var(--fusend-color-background);
  }
}

.discoverPeopleSearchSection {
  background: var(--fusend-color-background);
  text-align: center;
}

.discoverPeopleSearchIdText {
  margin-top: 9px;
  margin-bottom: 23px;
  font-size: 12px;
  color: var(--fusend-color-text-primary);
}

.discoverPeopleQRIcon {
  transform: translateY(3px); 
  font-size: 14px;
  color: var(--fusend-color-primary);
  margin-left: 6px;
}

.discoverPeopleSearchBar {
  --background: var(--fusend-color-background);
  padding: 24px 16px 10px 16px;
  text-align: start;
}

.discoverPeopleShareListItem {
  --background: inherit;
}

.discoverPeopleItemChevron {
  font-size: 23px;
  color: #E4E7EB;
}

.discoverPeopleItemAvatar {
  position: relative;
  text-align: center;
  width: 40px;
  height: 40px;
  background-color: rgba(235, 189, 182, 0.2);
  border-radius: 8px;

  ion-icon {
    color: var(--fusend-color-primary);
    font-size: 25px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

.discoverPeopleItemText {
  margin-left: 15px;

  p {
    font-size: 11px;
  }
}

.suggestPeopleLabel {
  font-weight: 500;
  margin-left: 16px;
  margin-top: 30px;
  margin-bottom: 14px;
  font-size: 16px;
}

.discoverSearchSpinner {
  width: 100%;
  text-align: center;
}

.searchResultListItem {
  --background: inherit;
  --inner-border-width: 0;
  --padding-start: 16px;

  .profilePicture {
    height: 41px;
    width: 41px;

    .fallbackProfilePicTextContainer {
      font-size: 20px;
    }
  }

  ion-label {
    margin-left: 27px;
  }
}

.searchResultAddFriendButton {
  --background: rgba(133,176,228, 50%);
  --padding-start: 17px;
  --padding-end: 17px;
  --padding-top: 12px;
  --padding-bottom: 12px;
  height: 31px;
  --border-radius: 4px;
  --color: var(--fusend-color-text-primary);
}