.tripActionBar {
  position: relative;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.detailPageActionBar {
    height: 40px;

    .tripActionBarItem {
      margin-left: 10px;

      &:first-of-type {
        margin-left: 0;
      }
    }
  }

  ion-button {
    --background: #464F6A;
    --background-activated: none;
    font-size: 14px;
    font-weight: 500;
    color: var(--fusend-color-black);

    .tripActionStartIcon {
      margin-right: 5px;
    }

    .tripActionEndIcon {
      margin-left: 3px;
    }
  }

  .tripActionBarItem {
    margin: 0 0 0 20px;
    height: 100%;

    &:first-of-type {
      margin-left: 0;
    }

    &.tripActionJoin {
      position: relative;
      position: relative;
      width: 100%;
      overflow: hidden;

      &.tripActionJoinInTrip {
        --background: var(--fusend-color-secondary);
      }

      &.tripActionJoinLarge {
        flex-grow: 1;
      }

      &.tripActionJoinSmall {
        width: 119px;
      }

      ion-select {
        position: absolute;
        height: 100%;
        width: 200%; // to cover the entire click area
        padding: 0;
        margin: 0;
        opacity: 0;
      }
    }

    &.tripActionInvite {
      &.tripActionInviteLarge {
        flex-grow: 1;
      }

      &.tripActionInviteSmall {
        min-width: 103px;
      }
    }

    &.tripActionChat {
      min-width: 40px;
      font-size: 9px;

      .tripActionStartIcon {
        margin-right: 0;
      }
    }
  }
}

.joinOptionSelect {

  .alert-wrapper {
    background: var(--fusend-color-background);
  }

  .alert-title {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
  }

  .alert-sub-title {
    font-size: 14px;
    font-weight: 200;
    text-align: center;
  }

  .alert-button-role-cancel,
  .action-sheet-button {
    color: var(--fusend-color-black);
  }

  .action-sheet-button {
    font-size: 16px;
    font-weight: 400;
  }

  .tripJoinOption {
    color: var(--fusend-color-black);
    font-size: 16px;
    font-weight: 400;

    .alert-radio-label {
      color: var(--fusend-color-black);
    }

    &.tripJoinOptionActive {
      color: var(--fusend-color-text-accent);

      .alert-radio-label {
        color: var(--fusend-color-text-accent);
      }
    }
  }
}